<template>
  <div class="page" v-if="!shirtConfirmation">
    <div class="text-block">

      <div style="color: #E41313; font-size: 10vw; "><span style="color: #fff;">LĪDZI</span> JĀŅEM<span style="color: #fff;">:</span></div>

      <div class="point-title" style="line-height: 11vw;"><span>I</span>.</div>
      <div style="font-size: 6vw; margin-bottom: 2%; line-height: 5vw; color: #868686;"><span style="color: #E41313;">PILNĪGI</span> <span style="color: #fff;">BALTS</span> <span style="color: #868686;">T-KREKLS.</span></div>
      <div style="font-size: 5vw; line-height: 5vw; color: #868686;">UZ ŠĪ <span style="color: #fff;">BALTĀ</span> <br/>T-KREKLA NEDRĪKST BŪT <span style="color: #E41313;">NEVIENS</span> CITAS KRĀSAS</div>
      <div style="font-size: 5vw; margin-bottom: 2%; line-height: 5vw; color: #868686;">ELEMENTS / LOGOTIPS / ŠUVE U.TML.</div>
      <div style="font-size: 5vw; line-height: 5vw; color: #868686;">APDRUKĀTS VAI UZ OTRU PUSI IZGRIEZTS <br/>T-KREKLS <span style="color: #E41313;">NESKAITĀS KĀ</span> PILNĪGI <span style="color: #fff;">BALTS</span> <br/>T-KREKLS.</div>
      <br/>

      <div style="font-size: 5vw; border: 0.5vw solid #E41313; padding: 5%; line-height: 5vw; color: #868686;">
        <template v-if="!invite.shirt">
          Ja Tev nav šāds T-KREKLS un laiks vai iespēja to iegūt, tad Tu to vari iegūt pirmās ierašanās laikā pret<br/>
          <span style="color: #E41313; font-size: 7vw; line-height: 7vw;">10 EUR</span><br/>
          ziedojumu skaidrā naudā, pretī saņemot stingrās uzskaites kvīti.<br/><br/>
          Ja Tu zemāk izvēlēsies atzīmēt sev vēlamo krekla izmēru, tad tiks uzskatīts, ka krekla <span style="color: #E41313;">rezervācija ir veikta.</span>
        </template>
        <template v-if="invite.shirt">
          <div style="color: #E41313;">Krekla rezervācija ir veikta.</div>
          <div>Tu sev rezervēji</div>
          <div style="color: #fff;">baltu</div>
          <div style="color: #E41313; font-size: 25vw; line-height: 23vw;">{{ invite.shirt }}</div>
          <div>izmēra kreklu,</div>
          <div>kuru saņemsi</div>
          <div>pirms</div>
          <div>pieredzes sākuma.</div>
        </template>
      </div>

      <div class="shirt-chooser">
        <div class="shirt-sizes" :class="{ 'choose-able': !invite.shirt }">
          <div class="shirt-size" :class="{ active: invite.shirt === 'XS' }" @click="!invite.shirt ? confirmShirt('XS') : ''">XS</div>
          <div class="shirt-size" :class="{ active: invite.shirt === 'S' }" @click="!invite.shirt ? confirmShirt('S') : ''">S</div>
          <div class="shirt-size" :class="{ active: invite.shirt === 'M' }" @click="!invite.shirt ? confirmShirt('M') : ''">M</div>
          <div class="shirt-size" :class="{ active: invite.shirt === 'L' }" @click="!invite.shirt ? confirmShirt('L') : ''">L</div>
          <div class="shirt-size" :class="{ active: invite.shirt === 'XL' }" @click="!invite.shirt ? confirmShirt('XL') : ''">XL</div>
        </div>
      </div>

      <div class="point-title"><span>2</span>.</div>
      <div style="font-size: 6vw; line-height: 6vw; color: #868686;">Fiziskiem treniņiem atbilstoši šorti/legingi/bikses.</div>

      <div class="point-title"><span>3</span>.</div>
      <div style="font-size: 6vw; line-height: 6vw; color: #868686;">Fiziskiem treniņiem atbilstoši apavi.</div>

      <div class="point-title"><span>4</span>.</div>
      <div style="font-size: 6vw; line-height: 6vw; color: #868686;">Ar negāzētu ūdeni piepildīta ūdens pudele.</div>

      <div class="point-title"><span>5</span>.</div>
      <div style="font-size: 6vw; line-height: 6vw; color: #868686;">Viss, kas Tev nepieciešams, lai nomazgātos un sakoptos pēc fiziskas slodzes.</div>

      <div class="point-title"><span>6</span>.</div>
      <div style="font-size: 6vw; line-height: 6vw; color: #868686;">Tajā un iepriekšējā dienā paēd, regulāri dzer ūdeni, izgulies, <span style="color: #E41313">nelieto</span> alkoholu un/vai citus apreibinošus mēslus.</div>
      <div style="font-size: 6vw; line-height: 6vw; color: #868686;"><span style="color: #E41313">Nesmēķē</span> pirms pieredzes.</div>
      <div style="font-size: 10vw; color: #E41313; line-height: 10vw;">Atmet?</div>

      <div class="point-title"><span>7</span>.</div>
      <div style="font-size: 6vw; line-height: 6vw; color: #E41313;">Nekādā gadījumā neierodies, ja Tev ir jebkādi jebkuras saslimšanas simptomi.</div>
      <div style="font-size: 6vw; line-height: 6vw; color: #E41313;">Ja tā notiek, obligāti sazinies un paziņo par to, nevis sūtot īsziņu, bet tikai piezvanot.</div>
      <div style="font-size: 6vw; line-height: 6vw; color: #E41313;"><span style="color: #868686;">+371 26394522</span></div>
      <br/><br/>

      <div style="color: #868686; font-size: 10vw; line-height: 9vw;"><span style="color: #fff;">Kad,</span></div>
      <div style="color: #868686; font-size: 10vw; line-height: 9vw;">kur,</div>
      <div style="color: #868686; font-size: 10vw; line-height: 9vw;"><span style="color: #E41313;">cikos?</span></div>
      <div style="font-size: 6vw; line-height: 6vw; color: #868686;">ESI ZEMĀK REDZAMAJĀ</div>
      <div style="font-size: 6vw; line-height: 6vw; color: #868686;">LOKĀCIJĀ</div>
      <div style="font-size: 7vw; line-height: 6vw; color: #E41313;">{{ invite.training_week_day }},<br/>{{ invite.training_month_day }}. {{ invite.training_month }},<br/>{{ invite.training_time }}.</div>
      <div style="font-size: 6vw; line-height: 6vw; color: #868686;">JA TU NOKAVĒSI KAUT VIENU SEKUNDI, TAD <br/>DALĪBA PIEREDZĒ BŪS LIEGTA.</div>

      <div class="location">
        <iframe allowfullscreen src="https://cdn.pannellum.org/2.5/pannellum.htm#panorama=https%3A//i.imgur.com/qyFYecr.jpg&autoLoad=true"></iframe>
      </div>

    </div>
  </div>

  <div class="shirt-confirmation" v-if="shirtConfirmation">
    <div class="text-section">
      <div class="section">
        <div class="text-block" style="color: #868686; line-height: 5vw;">
          <div>PĒC ŠĪ LĒMUMA PIEŅEMŠANAS</div>
          <div><span style="color: #E41313; font-size: 5vw;">ŠO LĒMUMU NEBŪS IESPĒJAS ATCELT.</span></div>
          <div>VAI <span style="color: #E41313">TIEŠĀM</span> VĒLIES, LAI TEV TIKTU SAGĀDĀTS<br/></div>
          <div><span style="color: #fff;">BALTS</span></div>
          <div style="color: #E41313; font-size: 25vw; line-height: 22vw;">{{ temporaryShirt }}</div>
          <div>IZMĒRA KREKLS</div>
          <div><span style="color: #E41313; font-size: 10vw; line-height: 11vw;">?</span></div>
          <div class="chooser-container">
            <div class="chooser">
              <a href="javascript:void(0);" @click="temporaryShirt = null; shirtConfirmation = null">NĒ</a>
            </div>
            <div class="chooser">
              <a href="javascript:void(0);" @click="setShirt(); shirtConfirmation = null">JĀ</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'InfoPage',
  computed: {
    ...mapState(['invite'])
  },
  data() {
    return {
      temporaryShirt: null,
      shirtConfirmation: null
    }
  },
  methods: {
    confirmShirt(size) {
      this.temporaryShirt = size
      this.shirtConfirmation = true
    },
    setShirt() {
      const url = new URL(window.location.href)
      const code = url.pathname.substring(1)
      this.$store.dispatch('setShirt', {
        code: code,
        shirt: this.temporaryShirt
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_includes.scss";

.location {
  margin-top: 4vw;

  iframe {
    border: none;
    width: 100%;
    height: 50vw;
  }
}

.point-title {
  font-size: 10vw;
  margin-top: 4vw;

  span {
    color: $red-color;
  }

  &:first-child {
    margin-top: 0;
  }
}

.shirt-chooser {
  margin-top: 4vw;

  .shirt-sizes {
    display: flex;
    align-items: center;
    justify-content: center;

    .shirt-size {
      margin: 0 1% 0 1%;
      font-size: 4.270833vw;
      color: #fff;
      text-align: center;
      width: 12.833333vw;
      height: 12.833333vw;
      line-height: 10.833333vw;
      flex-shrink: 0;
      border: 1vw solid #000;

      &.active {
        border: 1vw solid $red-color !important;
      }
    }

    &.choose-able {
      .shirt-size {
        cursor: pointer;

        &:hover {
          border: 1vw solid #fff;
        }
      }
    }
  }
}

.shirt-confirmation {
  color: #fff;
  position: absolute;
  background: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  .chooser-container {
    margin-top: 15%;
    display: flex;
    justify-content: center;

    .chooser {
      padding: 4vw;

      a {
        text-decoration: none;
        font-size: 10vw;
        color: #868686;

        &:hover {
          color: $red-color;
        }
      }
    }
  }
}
</style>
