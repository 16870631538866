<template>

  <TimeExpired
      v-if="timeExpired"
  ></TimeExpired>

  <IntroPage
      v-if="!introPageFinished && timeExpired === false"
      v-on:finishedIntroPage="introPageFinished = true"
  ></IntroPage>

  <CallPage
    v-if="introPageFinished"
  ></CallPage>

  <InfoPage
    v-if="infoPage"
  ></InfoPage>

  <SecondCallPage
      v-if="submittedChoicePage"
  ></SecondCallPage>

  <SecondChoicePage
      v-if="secondChoicePage && !submittedSecondChoicePage"
      v-on:submittedSecondChoicePage="submittedSecondChoicePage = true;"
  ></SecondChoicePage>

  <SecondChoiceCallPage
      v-if="submittedSecondChoicePage"
  ></SecondChoiceCallPage>

  <SecondInfoPage
      v-if="secondInfoPage"
  ></SecondInfoPage>

</template>

<script>
import {mapState} from "vuex"
import TimeExpired from "./components/TimeExpired";
import CallPage from "./components/CallPage";
import SecondCallPage from "./components/SecondCallPage";
import InfoPage from "./components/InfoPage";
import SecondChoicePage from "./components/SecondChoicePage";
import SecondInfoPage from "./components/SecondInfoPage";
import SecondChoiceCallPage from "./components/SecondChoiceCallPage";
import IntroPage from "./components/IntroPage";

export default {
  name: 'App',
  components: {
    IntroPage,
    SecondChoiceCallPage,
    SecondInfoPage,
    SecondChoicePage,
    InfoPage,
    CallPage,
    TimeExpired,
    SecondCallPage
  },
  computed: {
    ...mapState(['invite'])
  },
  data() {
    return {
      introPageFinished: null,
      timeExpired: null,
      infoPage: null,
      choicePage: null,
      secondChoicePage: null,
      submittedChoicePage: null,
      secondInfoPage: null,
      submittedSecondChoicePage: null
    }
  },
  watch: {
    invite: function (invite) {
      if (invite.status === 3) {
        this.timeExpired = true
      } else if (invite.status === 6) {
        this.introPageFinished = true
      } else if (invite.status === 7) {
        this.infoPage = true
      } else if (invite.status === 10) {
        this.submittedChoicePage = true
      } else if (invite.status === 11) {
        this.secondInfoPage = true
      } else if (invite.status === 13) {
        if (invite.second_chance) {
          this.submittedSecondChoicePage = true
        } else {
          this.secondChoicePage = true
        }
      } else {
        this.timeExpired = false
      }
    }
  },
  mounted() {
    const url = new URL(window.location.href)
    const code = url.pathname.substring(1)
    this.$store.dispatch('fetchInvite', code)
    this.$store.dispatch('submitInviteStatistics', {
      code: code,
      statistic_type: 'opened'
    })
  }
}
</script>

<style lang="scss">
@import "@/assets/css/_includes.scss";
</style>
