<template>
  <div class="text-section">
    <div class="text-block" style="font-size: 5vw; color: #868686">

      <span style="font-size: 15vw; line-height: 13vw; color: #fff;">TU</span><br/>
      <div style="line-height: 5vw;">PIEŅĒMI</div>
      <div style="line-height: 6vw;"><span class="red-color" style="font-size: 7vw;">NELAUŽAMU LĒMUMU.</span></div>
      <div style="line-height: 9vw; font-size: 9vw;">GAIDI<br/>ZVANU.</div>
      <div style="line-height: 5vw;">ŠĪ ZVANA LAIKĀ NOTIKS <span class="red-color">VIENOŠANĀS</span> PAR</div>
      <div style="line-height: 6vw; margin-top: 6%;"><span style="font-size: 15vw;">2 <span style="color: #fff;">/</span> <span style="color: #E41313">100</span></span></div>
      <div style="line-height: 5vw;">IERAŠANĀS</div>
      <div style="line-height: 5vw;">REIZI.</div><br/>

      <div style="line-height: 5vw;">VĀRDI IR <span style="color: #fff">GAISS</span>.</div>
      <div style="line-height: 5vw;">TO, KAS TU PATIESĪBĀ ESI, PARĀDĪS <span class="red-color">ILGTERMIŅŠ</span> UN<br/>TAVAS<br/> <span class="red-color">RĪCĪBAS</span><br/> <span class="red-color">REALITĀTE.</span></div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'SecondCallPage'
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_includes.scss";

</style>
