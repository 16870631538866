<template>
  <div class="text-section">
    <div class="text-block" style="color: #868686;">
      <div class="red-color" style="font-size: 25vw;line-height: 14vw;">JĀ<span style="color: #868686; font-size: 12vw">,</span></div>
      <div class="red-color" style="line-height: 5vw;">TU PIEŅĒMI NELAUŽAMU LĒMUMU<span style="color: #868686;">.</span></div><br/>
      <div style="line-height: 5vw;">PIRMĀ IZPRATNES REIZE VIENMĒR NOTIEK</div>
      <span class="red-color" style="font-size: 9vw; line-height: 9vw;">
        TIKAI DARBA DIENĀ<br/>
        PLKST<span style="color: #868686;">.</span> 06<span style="color: #868686;">:</span>30 NO RĪTA<span style="color: #868686;">.</span>
      </span><br/>
      <div style="line-height: 5vw;">
        IERAŠANĀS LAIKU DAŽĀDĪBA PLAŠA BŪS<span class="red-color">,</span> JA TU PĒC PIRMĀS REIZES PAR BIEDRU IZVĒLĒSIES KĻŪT<span class="red-color">.</span><br/>
        TEV PIEZVANĪS<span class="red-color">.</span>
      </div>
      <span style="color: #fff">GAIDI</span><span class="red-color">.</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CallPage'
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_includes.scss";

</style>
