import {createApp} from 'vue'
import App from './App.vue'
import NoSleep from 'nosleep.js';
import vueVimeoPlayer from 'vue-vimeo-player'
import store from '@/store'

const app = createApp(App)
app.use(vueVimeoPlayer)
app.use(store)
app.mount('#app')

const noSleep = new NoSleep()
noSleep.enable()