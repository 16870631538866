import {createStore} from "vuex"
import axios from "axios"

const store = createStore({
    state() {
        return {
            invite: {
                status: null,
                end_time: null
            }
        }
    },
    mutations: {
        getInvite(state, invite) {
            state.invite = invite
        },
        setStatus(state, status) {
            state.invite.status = status
        },
        setShirt(state, shirt) {
            state.invite.shirt = shirt
        }
    },
    actions: {
        async fetchInvite(context, code) {
            if (!code) {
                window.location.replace('https://google.com')
            }
            axios.post(process.env.VUE_APP_API_LINK + '/get-invite', {
                code: code
            }, {})
                .then(response => {
                    if (response.data.status === 'success') {
                        if (response.data.invite) {
                            context.commit('getInvite', response.data.invite)
                        } else {
                            window.location.replace('https://google.com')
                        }
                    } else {
                        window.location.replace('https://google.com')
                    }
                })
        },
        async submitInviteStatistics(context, data) {
            if (!data.code) {
                window.location.replace('https://google.com')
            }
            axios.post(process.env.VUE_APP_API_LINK + '/submit-invite-statistics', {
                code: data.code,
                statistic_type: data.statistic_type
            }, {}).then()
        },
        async setInviteStatus(context, data) {
            axios.post(process.env.VUE_APP_API_LINK + '/set-invite-status', {
                code: data.code,
                status: data.status
            }, {})
                .then(response => {
                    if (response.data.status === 'success') {
                        context.commit('setStatus', data.status)
                    }
                })
        },
        async setShirt(context, data) {
            axios.post(process.env.VUE_APP_API_LINK + '/set-shirt', {
                code: data.code,
                shirt: data.shirt,
            }, {}).then(response => {
                if (response.data.status === 'success') {
                    context.commit('setShirt', data.shirt)
                }
            })
        },
        async submitSecondChoice(context, data) {
            axios.post(process.env.VUE_APP_API_LINK + '/submit-second-choice', {
                code: data.code
            }, {}).then()
        }
    }
})

export default store;
