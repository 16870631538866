<template>
  <div class="text-section">

    <template v-if="invite.status === 9">

      <div class="text-block" style="padding: 0; color: #868686;">
        <div style="font-size: 10vw; line-height: 9vw;">Šī iespēja<br/><span style="color: #E41313">pazudīs</span> pēc<span style="color: #E41313;">:</span></div>
      </div>

      <Timer></Timer>

      <div class="text-block" style="line-height: 5vw;">
        <div>TU ŠOBRĪD LASI ŠO,</div>
        <div>JO <span class="red-color">VĒLIES IESPĒJU</span> TU <span class="red-color">PĒDĒJO.</span></div>
        LAI TO <span class="red-color">SAŅEMTU</span> VARBŪT,<br/>
        RĀMĪ ZEMĀK RAKSTI VĀRDUS:
        <div class="big-text" style="line-height: 6vw; font-size: 7vw;">"<span class="red-color">ES</span></div>
        <div class="big-text" style="line-height: 6vw; font-size: 7vw;"><span class="red-color">TO</span></div>
        <div class="big-text" style="line-height: 6vw; font-size: 7vw;"><span class="red-color">VĒLOS</span></div>
        <div class="big-text" style="line-height: 6vw; font-size: 7vw;"><span class="red-color">GŪT</span>"</div>
      </div>

      <div class="submit-page-container">
        <textarea :value="input" @input="e => input = e.target.value" rows="2" autocomplete="off" spellcheck="false"></textarea>
      </div>
    </template>

    <template v-if="invite.status === 13">
      <div class="text-block" style="font-size: 10vw; line-height: 9vw; color: #E41313;">
        GAIDI,<br/>
        Tev<br/>
        varbūt<br/>
        piezvanīs.
      </div>
    </template>

  </div>
</template>

<script>

import Timer from "./Timer";
import {mapState} from "vuex";

export default {
  name: 'SecondChoicePage',
  emits: ['submittedSecondChoicePage'],
  components: {Timer},
  computed: {
    ...mapState(['invite'])
  },
  data() {
    return {
      input: null
    }
  },
  watch: {
    input: function (value) {
      if (value.toUpperCase() === 'ES TO VĒLOS GŪT' ||
          value.toUpperCase() === 'ES TO VĒLOS GUT' ||
          value.toUpperCase() === 'ES TO VELOS GUT' ||
          value.toUpperCase() === 'ES TO VELOS GŪT' ||
          value.toUpperCase() === 'ESTOVĒLOSGŪT' ||
          value.toUpperCase() === 'ESTOVĒLOSGUT' ||
          value.toUpperCase() === 'ESTOVELOSGUT' ||
          value.toUpperCase() === 'ESTOVELOSGŪT'
      ) {
        const url = new URL(window.location.href)
        const code = url.pathname.substring(1)
        this.$store.dispatch('submitSecondChoice', {
          code: code
        })
        this.$emit('submittedSecondChoicePage')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_includes.scss";
</style>
