<template>
  <div class="page intro-font" v-bind:class="{ hidden: warning || areYouSure || endMessage }">

    <div class="text-block red-color" style="font-size: 7vw;"><span class="red-color">.---</span> NOSKATIES <span class="red-color">---.</span></div>

    <div class="video-wrapper">
      <vimeo-player ref="player" :video-id="'682762313'" :controls="true" :options="{playsinline: true}" @play="playedVideo"></vimeo-player>
    </div>

    <Timer></Timer>

    <div class="text-block" style="color: #868686;">
      JA TU TEIKSI <span class="red-color">"JĀ"</span>, TAD TU TEIKSI <span class="red-color">"JĀ"</span> TIKAI VIENAI REIZEI UN NEKAM VAIRĀK.<br/>
      PATEIKT <span class="red-color">"JĀ"</span> UN IERASTIES UZ PIRMO IZPRATNES REIZI<br/>
      <span class="red-color" style="font-size: 7vw;">NENOZĪMĒ</span><br/>
      <span style="color: #aaaaaa;">DOT NELAUŽAMU SOLĪJUMU SEV PAR 100 REIZĒM, KURAS JĀNOIET, NESKATOTIES NE UZ KO.</span><br/>
      PATIESĪBA VIENMĒR PALIKS VIENA - TIKAI <span class="red-color">REGULĀRĀ ILGTERMIŅĀ</span> PĀRMAIŅAS UN JAUNI IERADUMI TOP.<br/>
      <span class="red-color">TIE, KURI</span> NESAGATAVOTI UN <span class="red-color">VĀJI</span>, LĒNĀM UN PA VIENAM SOLIM ŠEIT <span class="red-color">SPĒCĪGĀKI KĻŪST</span>.<br/>
      SPĒCĪGS CILVĒKS TAS, KURŠ ZINA, KA <span style="color: #aaaaaa;">JĀATZĪST SAVS VĀJUMS PIRMS VAR SĀKTIES SPĒKA CEĻŠ</span>.<br/>
      UN <span class="red-color">TIE, KAS</span> ĶERMENĪ IR <span class="red-color">SPĒCĪGI</span>, ŠEIT <span class="red-color">PĀRBAUDĪTI BŪS.</span><br/>
      SPĒCĪGS CILVĒKS TAS, KURŠ SPĒJĪGS <span style="color: #aaaaaa;">PACELT DISCIPLĪNU, PIEŅEMŠANU, MIERU</span>, NE TIKAI HANTELES UN SVARA STIEŅUS.<br/>
      NAUDA NAV JĀMAKSĀ.<br/>
      <span class="red-color">TU ESI VIESIS</span>,<br/>
      UN PAR ŠO VIENU REIZI TEV IR<br/>
      <div style="line-height: 4.7vw;"><span class="red-color" style="font-size: 7vw;">JĀMAKSĀ AR CIEŅU</span>,</div>
      KURU IZRĀDĪSI ŠĪS TRENIŅU PIEREDZES NOTEIKUMIEM UN FILOSOFIJAI.
    </div>

    <div class="choice-container">
      <div class="choice">
        <a href="javascript:void(0);" @click="decline()" v-if="!validInputs">NĒ<span>,</span></a>
        <div class="choice-text" style="color: #868686;">
          ES NEVĒLOS IERASTIES UZ VIENU IZPRATNES REIZI. ES NEVĒLOS SAŅEMT ATGĀDINĀJUMUS UN ES NEVĒLOS PIEEJU ŠIM UZAICINĀJUMAM.<br/>
          <span style="color: #E41313; font-size: 12vw; line-height: 0vw;">.</span>
        </div>
      </div>
      <div class="choice">
        <a href="javascript:void(0);" @click="submit()">JĀ<span>,</span></a>
        <div class="choice-text" style="color: #868686;">
          ES PIEŅEMU NELAUŽAMU LĒMUMU IERASTIES UZ <span style="color: #aaaaaa;">NEKO VAIRĀK KĀ VIENU</span> IZPRATNES REIZI. UN SAVU LĒMUMU ES PIERĀDĪŠU AR SPĒJU NOTURĒT NEMAINĪGU PRĀTA STĀVOKLI UN ĪSTU RĪCĪBU REALITĀTĒ.<br/>
          <span style="color: #E41313; font-size: 12vw; line-height: 0vw;">.</span>
        </div>
      </div>
    </div>

  </div>

  <div class="confirmation" v-if="warning" @click="warning = null">
    <div class="text-section">
      <div class="section">
        <div class="text-block">
          <div style="color: #E41313; line-height: 8vw; font-size: 8vw;">Tu neievadīji visu</div>
          <div style="color: #868686; line-height: 5vw;">pieprasīto</div>
          <div style="color: #868686; line-height: 5vw;">informāciju</div>
          <div style="color: #868686; line-height: 5vw;">par</div>
          <div style="color: #868686; line-height: 5vw;">sevi.</div>
          <div style="color: #E41313; line-height: 8vw; font-size: 8vw;">izdari to.</div>
        </div>
      </div>
    </div>
  </div>

  <div class="confirmation" v-if="areYouSure">
    <div class="text-section">
      <div class="section">
        <div class="text-block">
          <div style="color: #868686; line-height: 5vw;">TU IZVĒLĒJIES</div>
          <div style="color: #fff; line-height: 24vw; font-size: 22vw;">"<span style="color: #E41313;">NĒ</span>"</div>
          <div style="color: #868686; line-height: 5vw;">VAI TU PALIEC PIE ŠĪ LĒMUMA?</div>
        </div>
        <div class="chooser-container">
          <div class="chooser">
            <a href="javascript:void(0);" @click="areYouSure = null" style="color: #fff;">NĒ<span style="color: #E41313;">.</span></a>
          </div>
          <div class="chooser">
            <a href="javascript:void(0);" @click="submitDecline()">JĀ<span style="color: #fff;">.</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="confirmation" v-if="endMessage">
    <div class="text-section">
      <div class="section">
        <div class="text-block" style="color: #868686;">
          <div class="red-color" style="font-size: 25vw;">NĒ<span style="color: #868686;">.</span></div>
          <div class="red-color" style="line-height: 5vw;">
            TU VAIRS NESAŅEMSI SMS ATGĀDINĀJUMUS<span style="color: #868686;">.</span><br/>
            TAVA PIEEJA ŠIM UZAICINĀJUMAM NO ŠĪ BRĪŽA IR SLĒGTA<span style="color: #868686;">.</span>
          </div><br/>
          <div class="red-color" style="line-height: 5vw;">PAGRABS</div>
          <div style="line-height: 5vw;">TEV ZEMU PAKLANĀS UN IZRĀDA CIEŅU PAR TO, KA SNIEDZI KONKRĒTU ATBILDI UN PIEŅĒMI UZAICINĀJUMA PROCESA STRIKTOS NOTEIKUMUS<span class="red-color">.</span></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";
import Timer from "./Timer";

export default {
  name: 'IntroPage',
  components: {Timer},
  emits: ['finishedIntroPage'],
  computed: {
    ...mapState(['invite'])
  },
  data() {
    return {
      validInputs: false,
      warning: null,
      areYouSure: null,
      endMessage: null
    }
  },
  methods: {
    submit() {
      const url = new URL(window.location.href)
      const code = url.pathname.substring(1)
      this.$store.dispatch('setInviteStatus', {
        code: code,
        status: 6
      })
      this.$emit('finishedIntroPage')
    },
    decline() {
      this.areYouSure = true
    },
    submitDecline() {
      const url = new URL(window.location.href)
      const code = url.pathname.substring(1)
      this.$store.dispatch('setInviteStatus', {
        code: code,
        status: 5
      })
      this.areYouSure = false
      this.endMessage = true
    },
    playedVideo() {
      const url = new URL(window.location.href)
      const code = url.pathname.substring(1)
      this.$store.dispatch('submitInviteStatistics', {
        code: code,
        statistic_type: 'video-played'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_includes.scss";

.intro-font {
  font-family: Tahyp-rahy-ter22Dirty, sans-serif;
}

.choice-container {
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
  text-align: center;

  .choice {
    width: 45%;

    a {
      text-decoration: none;
      margin: 0 5%;
      font-size: 24vw;
      color: #E41313;
      line-height: 10vw;

      span {
        font-size: 12vw;
        color: #fff;
      }

      &:hover {
        color: #fff;
      }
    }

    .choice-text {
      color: #fff;
      line-height: 4vw;
      font-size: 4vw;
    }
  }
}

.buttons-container {
  display: flex;
  justify-content: center;

  a {
    color: #fff;
    text-decoration: none;
    margin: 0 5%;

    &:hover {
      color: #E41313;
    }
  }
}

ul {
  list-style: none inside none;

  li {
    font-size: 5vw;
    line-height: 5vw;

    &::before {
      content: "\2022";
      color: #E41313;
      font-weight: bold;
      display: inline-block;
      font-size: 6vw;
      width: 1em;
      margin-left: -1em;
    }
  }
}

.submit-page-container {
  margin-top: 0;
}

.confirmation {
  color: #fff;
  position: absolute;
  background: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;

  ::v-deep(iframe) {
    display: block;
    background: #000;
    margin-bottom: 13vw;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

.chooser-container {
  margin-top: 15%;

  .chooser {
    padding: 4vw;

    a {
      text-decoration: none;
      font-size: 12vw;
      color: $red-color;

      &:hover {
        color: #fff;
      }
    }

    &:first-child {
      a {
        &:hover {
          color: $red-color !important;
        }
      }
    }
  }
}
</style>
