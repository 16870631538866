<template>
  <div class="text-section">
    <div class="text-block" style="color: #868686;">
      <div style="font-size: 13vw; line-height: 13vw;">tu</div>
      <div style="line-height: 5vw;">izvēlējies,</div>
      <div style="line-height: 5vw;">ka TAVA</div>
      <div style="line-height: 5vw; margin-top: 5%;"><span style="font-size: 15vw;"><span style="color: #fff;">2</span> / <span style="color: #E41313">100</span></span></div>
      <div style="line-height: 5vw;">REIZE notiks:</div>
      <div style="line-height: 5vw; margin-top: 4%;"><span style="font-size: 10vw; color: #E41313">{{ invite.training_week_day }},</span></div>
      <div style="line-height: 5vw; margin-top: 4%;"><span style="font-size: 10vw; color: #E41313">{{ invite.training_month_day }}. {{ invite.training_month }},</span></div>
      <div style="line-height: 5vw; margin-top: 4%;"><span style="font-size: 10vw; color: #E41313">{{ invite.training_time }}</span></div>
      <div style="line-height: 5vw;">TU ZINI, KUR TEV JĀBŪT.</div>
      <div style="line-height: 5vw;">NEKAVĒ.</div>
      <div style="line-height: 5vw;">TU ZINI VISU, KO TU NEDRĪKSTI NEPAŅEMT SEV LĪDZI.</div><br/>

      <div style="line-height: 2vw; color: #E41313; font-size: 2vw;">VARBŪT PADODIES?</div>
      <div style="line-height: 2vw; color: #E41313; font-size: 2vw;">VIENKĀRŠI NEIERODIES,<br/>IZVAIRIES,<br/>VAIRĀK NEKAD NECEL TELEFONU<br/>UN IZDOMĀ PERFEKTU STĀSTU,<br/>KURU IESTĀSTĪT<br/>SEV.</div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'SecondInfoPage',
  computed: {
    ...mapState(['invite'])
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_includes.scss";

.location {
  margin-top: 4vw;

  iframe {
    border: none;
    width: 100%;
    height: 50vw;
  }
}

.point-title {
  font-size: 10vw;
  margin-top: 4vw;

  &:first-child {
    margin-top: 0;
  }
}

.shirt-chooser {
  margin-top: 4vw;

  .shirt-sizes {
    display: flex;
    align-items: center;
    justify-content: center;

    .shirt-size {
      margin: 0 1% 0 1%;
      font-size: 8.270833vw;
      color: #fff;
      text-align: center;
      width: 18.833333333333332vw;
      height: 18.833333333333332vw;
      line-height: 16.833333333333332vw;
      flex-shrink: 0;
      cursor: pointer;
      border: 1vw solid #000;

      &.active {
        border: 1vw solid $red-color !important;
      }

      &:hover {
        border: 1vw solid #fff;
      }
    }
  }
}

.shirt-confirmation {
  color: #fff;
  position: absolute;
  background: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  .chooser-container {
    display: flex;
    justify-content: center;

    .chooser {
      padding: 4vw;

      a {
        text-decoration: none;
        font-size: 10vw;
        color: #fff;

        &:hover {
          color: $red-color;
        }
      }
    }
  }
}
</style>
