<template>
  <div class="text-section">
    <div class="text-block" style="color: #868686;">
      <div style="font-size: 13vw; line-height: 12vw; color: #E41313;">GAIDI.</div>
      <div style="line-height: 5vw;">VARBŪT SAGAIDĪSI ZVANU.</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SecondChoiceCallPage'
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_includes.scss";

</style>
