<template>
  <div class="text-section">
    <div class="text-block">
      <div style="font-size: 10vw; color: #E41313; line-height: 9vw;">Laiks</div>
      <div style="font-size: 10vw; color: #868686; line-height: 9vw;">iztecējis</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TimeExpired',
  props: ['newStatus'],
  mounted() {
    const url = new URL(window.location.href)
    const code = url.pathname.substring(1)
    this.$store.dispatch('setInviteStatus', {
      code: code,
      status: 4
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_includes.scss";

</style>
