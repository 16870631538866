<template>
  <div v-if="show" class="timer-container">
    <div class="timer-block">
      <div class="timer-block-title">DIENAS</div>
      <div class="timer-block-time days">{{ days }}</div>
    </div>
    <div class="timer-separator">:</div>
    <div class="timer-block">
      <div class="timer-block-title">STUNDAS</div>
      <div class="timer-block-time hours">{{ hours }}</div>
    </div>
    <div class="timer-separator">:</div>
    <div class="timer-block">
      <div class="timer-block-title">MINŪTES</div>
      <div class="timer-block-time minutes">{{ minutes }}</div>
    </div>
    <div class="timer-separator">:</div>
    <div class="timer-block">
      <div class="timer-block-title">SEKUNDES</div>
      <div class="timer-block-time seconds">{{ seconds }}</div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'Timer',
  emits: ['loadedTimer'],
  computed: {
    ...mapState(['invite'])
  },
  data() {
    return {
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
      show: false,
      updatedFired: false
    }
  },
  mounted() {
    const countDownDate = new Date(this.invite.end_time).getTime()
    const self = this
    setInterval(function () {
      const now = new Date().getTime()
      const distance = countDownDate - now
      const days = (Math.floor(distance / (1000 * 60 * 60 * 24)))
      self.days = days.toString().length === 1 ? '0' + days : days
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      self.hours = hours.toString().length === 1 ? '0' + hours : hours
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      self.minutes = minutes.toString().length === 1 ? '0' + minutes : minutes
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)
      self.seconds = seconds.toString().length === 1 ? '0' + seconds : seconds
      self.show = true
    }, 1000)
  },
  updated() {
    if (!this.updatedFired) {
      this.updatedFired = true
      this.$emit('loadedTimer')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_includes.scss";

.timer-container {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: Old-Typewriter, Courier-Polski, sans-serif;

  .timer-block {
    font-size: 3vw;

    .timer-block-title {
      color: $red-color;
      font-size: 3.5416666666666665vw;
    }

    .timer-block-time {
      color: $grey-color;
      line-height: 15vw;
      font-size: 16vw;
    }
  }

  .timer-separator {
    padding-top: 9vw;
    font-size: 6vw;
    color: $red-color;
  }
}
</style>